// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

exports.linkResolver = function linkResolver (doc) {
  // Route for about us
  if (doc.type === 'aboutus') {
    return '/about-us'
  }

  // Route for articles
  if (doc.type === 'article') {
    return '/article/' + doc.uid
  }

  // Route for careers
  if (doc.type === 'careers') {
    return '/careers'
  }

  // Route for contact us
  if (doc.type === 'contactus') {
    return '/contact-us'
  }

  // Route for privacy policy
  if (doc.type === 'privacycookiepolicy') {
    return '/privacy-cookie-policy'
  }

  // Route for product
  if (doc.type === 'product') {
    return '/product'
  }

  // Route for blog post
  if (doc.type === 'blogpost') {
    return '/blog/' + doc.uid
  }

  // Route for risk finance
  if (doc.type === 'riskfinance') {
    return doc.uid
  }

  // Homepage route fallback
  return '/'
}

module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"scootscience","defaultLang":"en-us","accessToken":"MC5YdFVMenhBQUFDTUFKQUZB.a--_ve-_ve-_vWkLQGQZ77-977-9XH7vv73vv73vv71zL2cj77-977-9XH3vv73vv73vv70DVEdh77-9","path":"/preview","previews":true,"pages":[{"type":"Article","match":"/article/:uid","path":"/article-preview","component":"/opt/build/repo/src/templates/article.js"},{"type":"Blogpost","match":"/blog/:uid","path":"/blog-preview","component":"/opt/build/repo/src/templates/blogpost.js"},{"type":"Riskfinance","match":"/:uid","path":"/riskfinance-preview","component":"/opt/build/repo/src/templates/riskfinance.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Scoot Science","short_name":"Scoot","start_url":"/","background_color":"#358BDB","theme_color":"#358BDB","display":"standalone","icon":"src/images/icons/icon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7a6180dc58915dc78ba1a7ec3158d9d8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137255759-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
